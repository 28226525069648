import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { PageProps } from "gatsby"

import LayoutMdx from "../components/LayoutMdx"

import { Seo } from "../components/Seo/Seo"
import { Navigation, Footer, Hero, Breadcrumb } from "../components/Marketing"
import { ArticleEntity } from "../entities/ArticleEntity"
import { Haikus } from "../components/article"
import { getImage } from "gatsby-plugin-image"

const ArticlePage: React.FC<PageProps<
  any,
  ArticleEntity & {
    more: ArticleEntity[]
    haikus: {
      theme: string
      content: string
      id: string
      author: string
    }[]
  }
>> = props => {
  const article = props.pageContext
  const categories = article.categories
  const breadcrumbs: Array<{
    label: string
    url: string
  }> = categories
    .map(category => ({ label: category.name, url: category.url }))
    .concat({ label: article.display__title, url: article.url })
  const illustration = getImage(article.image)

  return (
    <LayoutMdx>
      <Seo
        article={{ ...article, image: illustration }}
        title={article.seo__title}
        description={article.seo__description}
        breadcrumbs={breadcrumbs}
        image={illustration.images.fallback.src}
      />
      <Navigation />
      <Hero
        theme={{
          as: "nav",
          value: <Breadcrumb items={breadcrumbs} />,
        }}
        title={{
          as: "h1",
          raw: true,
          value: article.title,
        }}
        description={{
          as: "p",
          raw: true,
          value: article.description,
        }}
        author={article.author}
        date={{
          created_at: article.created_at,
          updated_at: article.updated_at,
        }}
        reading={article.reading}
        illustration={illustration}
      />

      <MDXRenderer>{article.html}</MDXRenderer>

      {/* @TODO ajouter l'url de l'auteur */}
      <Haikus
        haikus={props.pageContext.haikus.map(haiku => ({
          content: haiku.content,
          category: haiku.theme,
          author: {
            fullName: haiku.author,
          },
        }))}
      />

      <Footer />
    </LayoutMdx>
  )
}

export default ArticlePage
